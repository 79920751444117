import styled from 'styled-components';

import { Icon as CommonIcon } from 'components/common';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  user-select: none;
  cursor: pointer;
  color: ${(p) => p.theme.colours.primary.dark4};
  > span {
    font-size: 14px;
  }
  svg {
    fill: ${(p) => p.theme.colours.primary.dark4};
  }

  ${(p) => p.theme.breakpoints.desktop} {
    color: ${(p) =>
      p.isActive
        ? p.theme.colours.secondary.two
        : p.theme.colours.primary.dark4};
    transition: 0.3s all ease;
    svg {
      transition: 0.3s all ease;
      fill: ${(p) =>
        p.isActive
          ? p.theme.colours.secondary.two
          : p.theme.colours.primary.dark4};
    }
  }
`;

const Icon = styled(CommonIcon)`
  height: 16px;
  margin-right: 12px;
`;

const Chevron = styled(CommonIcon)`
  width: 12px;
  transform: rotate(270deg);
  margin-left: auto;
`;

const Option = ({ label, icon, onClick, isActive }) => {
  return (
    <Container onClick={onClick} isActive={isActive}>
      <Icon icon={icon} />
      <span>{label}</span>
      <Chevron icon='avatarChevron' />
    </Container>
  );
};
export default Option;
