import {
  ProgressionContainer,
  DotContainer,
  Circle,
  DotLabel,
  DotLine,
  ActiveLine,
  Required,
} from './styled';

const Dot = ({ activeArea, label, number, onClick, required }) => {
  const active = activeArea >= number;
  return (
    <DotContainer onClick={() => onClick(number)}>
      <Circle active={active}>{number}</Circle>
      <DotLabel active={active}>
        {label}
        {required && <Required title='Required fields missing'>!</Required>}
      </DotLabel>
    </DotContainer>
  );
};

const Progression = ({ onChange, activeArea, details, loading }) => {
  const requiredDetails = [
    'arrivedDate',
    'colour',
    'dateOfBirth',
    'location',
    'name',
    'sex',
    'species',
    'status',
  ];
  if (details.species === 'Dog') {
    requiredDetails.push('dominantBreed', 'size');
  }
  const missing = {
    details: requiredDetails.some(
      (f) => !details[f] || (Array.isArray(f) && f.length === 0)
    ),
    financial: ['rehomingFee'].some((f) => !details[f] || !details[f].amount),
    personality:
      ['socialBlurb'].some((f) => !details[f]) &&
      details.status === 'Available to adopt',
    homeRequirements:
      ['minimumKidAge', 'liveWithDogs', 'liveWithCats'].some(
        (f) => !details[f] || (Array.isArray(f) && f.length === 0)
      ) && details.status === 'Available to adopt',
  };

  return (
    <ProgressionContainer>
      <DotLine />
      <ActiveLine activeArea={activeArea} />
      <Dot
        onClick={onChange}
        activeArea={activeArea}
        number={1}
        label='Basic Details'
        required={!loading && missing.details}
      />
      <Dot
        onClick={onChange}
        activeArea={activeArea}
        number={2}
        label='Health'
        required={!loading && missing.health}
      />
      <Dot
        onClick={onChange}
        activeArea={activeArea}
        number={3}
        label='Financial'
        required={!loading && missing.financial}
      />
      <Dot
        onClick={onChange}
        activeArea={activeArea}
        number={4}
        label='Home Requirements'
        required={!loading && missing.homeRequirements}
      />
      <Dot
        onClick={onChange}
        activeArea={activeArea}
        number={5}
        label='Personality'
        required={!loading && missing.personality}
      />
    </ProgressionContainer>
  );
};

export default Progression;
