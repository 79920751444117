import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import theme from 'utils/theme';
import permissions from 'utils/permissions';
import {
  Auth,
  Dashboard,
  Pet,
  Charities,
  Charity,
  Users,
  User,
  Settings,
} from 'components';
import { AuthedRoute } from 'components/common';
import { AuthProvider, MenuProvider, NotificationProvider } from 'hooks';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const GlobalStyles = createGlobalStyle`
  body {
    color: ${(p) => p.theme.colours.primary.dark2};
  }
`;

const App = () => {
  return (
    <AuthProvider>
      <MenuProvider>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <GlobalStyles />
            <Router>
              <ScrollToTop />
              <Switch>
                <AuthedRoute exact path='/'>
                  <Dashboard />
                </AuthedRoute>
                <Route path='/login'>
                  <Auth />
                </Route>
                <Route path='/setPassword'>
                  <Auth />
                </Route>
                <Route path='/resetPassword'>
                  <Auth />
                </Route>
                <Route path='/signup'>
                  <Auth />
                </Route>
                <AuthedRoute path='/pet/:id?'>
                  <Pet />
                </AuthedRoute>
                <AuthedRoute path='/charities' needs={permissions.PAW_ADMIN}>
                  <Charities />
                </AuthedRoute>
                <AuthedRoute path='/charity/:id?' needs={permissions.PAW_ADMIN}>
                  <Charity />
                </AuthedRoute>
                <AuthedRoute path='/users' needs={permissions.CHARITY_ADMIN}>
                  <Users />
                </AuthedRoute>
                <AuthedRoute
                  path='/user/:id?'
                  needs={permissions.CHARITY_ADMIN}
                >
                  <User />
                </AuthedRoute>
                <AuthedRoute path='/settings'>
                  <Settings />
                </AuthedRoute>
              </Switch>
            </Router>
          </NotificationProvider>
        </ThemeProvider>
      </MenuProvider>
    </AuthProvider>
  );
};

export default App;
