import styled from 'styled-components';
import { useHistory } from 'react-router';

import { useWidth } from 'hooks';
import { Table, Icon } from 'components/common';

import MobileCard from './MobileCard';
import Status from './Status';
import { format } from 'utils/date';

const Check = styled(Icon)`
  width: 16px;
  stroke: ${(p) => p.theme.colours.secondary.two};
  fill: none;
`;

const CharityName = ({ children }) => (
  <div style={{ fontWeight: 700, fontSize: 16 }}>{children}</div>
);

const Date = ({ children }) => <div>{format(children)}</div>;

const Boolean = ({ children }) => (
  <div>{children && <Check icon='check' />}</div>
);

const columns = [
  { label: 'Name', key: 'name', cell: CharityName },
  { label: 'Date Added', key: 'createdAt', cell: Date },
  { label: 'Status', cell: Status },
  { label: 'Fosterer', key: 'isFosterer', cell: Boolean },
];

const List = ({ users, loading }) => {
  const { isDesktop } = useWidth();
  const { push } = useHistory();

  const handleClick = ({ _id }) => {
    push(`/user/${_id}`);
  };

  if (loading) return null;

  if (isDesktop) {
    return <Table data={users} columns={columns} onRowClick={handleClick} />;
  }

  return users.map((u, i) => (
    <MobileCard user={u} key={i} onClick={handleClick} />
  ));
};

export default List;
