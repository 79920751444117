import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, Button } from 'components/common';
import { usePost, useAuth } from 'hooks';
import { pet as petEndpoint } from 'services/api';

import Form from './Form';
import ImageSelector from './ImageSelector';

const FormInputs = styled.div`
  width: 100%;
  overflow: scroll;
  > div {
    margin-bottom: 15px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > button {
    margin-right: 10px;
  }
`;

const AddModal = ({ show, onClose, options }) => {
  const [details, setDetails] = useState({
    arrivedDate: new Date().toISOString(),
  });
  const [images, setImages] = useState([]);
  const { push } = useHistory();
  const [showCropper, setShowCropper] = useState(false);
  const { user } = useAuth();
  const [{ res: savedPet, loading: savingPet }, postPet] = usePost({
    url: petEndpoint,
  });

  let requiredFields = [
    'name',
    'species',
    'status',
    'location',
    'arrivedDate',
    'sex',
    'colour',
    'dateOfBirth',
    'rehomingFee',
  ];

  useEffect(() => {
    if (savedPet && savedPet._id) {
      push(`/pet/${savedPet._id}`);
    }
  }, [savedPet, push]);

  const getFormProps = (key) => ({
    onChange: (val) => {
      setDetails((s) => ({ ...s, [key]: val }));
    },
    value: details[key],
    disabled: savingPet,
    required: true,
  });

  const handleChangeImages = (images) => {
    if (images) {
      setShowCropper(true);
    }
    setImages(images);
  };

  const handleSave = () => {
    postPet({ ...details, images });
  };

  if (!options) return null;
  if (details.species === 'Dog') {
    requiredFields = [...requiredFields, 'dominantBreed', 'size'];
  }
  if (details.status === 'Available to adopt') {
    requiredFields = [
      ...requiredFields,
      'minimumKidAge',
      'liveWithDogs',
      'liveWithCats',
      'socialBlurb',
    ];
  }
  if (user.isPawAdmin) {
    requiredFields = [...requiredFields, 'charityId'];
  }

  const handleChangeCrop = (crop) =>
    setDetails((s) => ({ ...s, imageCrop: crop }));

  const disableSave = requiredFields.some((f) => !details[f]) || savingPet;

  return (
    <Modal show={show} onClose={onClose}>
      <FormInputs>
        {!showCropper && (
          <Form
            details={details}
            getFormProps={getFormProps}
            options={options}
            handleChangeImages={handleChangeImages}
            images={images}
          />
        )}
        {images && showCropper && (
          <ImageSelector
            images={images}
            onChangeCrop={handleChangeCrop}
            onChangeDetails={setDetails}
            onChangeCropper={setShowCropper}
          />
        )}
        <ActionsContainer>
          {!showCropper && (
            <Button type='secondary' onClick={onClose}>
              Cancel
            </Button>
          )}
          {!showCropper && (
            <Button onClick={handleSave} disabled={disableSave}>
              Save
            </Button>
          )}
        </ActionsContainer>
      </FormInputs>
    </Modal>
  );
};

export default AddModal;
