import styled from 'styled-components';
import { useHistory } from 'react-router';

import { useWidth } from 'hooks';
import { Table, Icon } from 'components/common';

import MobileCard from './MobileCard';
import { format } from 'utils/date';

const Check = styled(Icon)`
  width: 15px;
  stroke: ${(p) => p.theme.colours.secondary.two};
  fill: none;
`;
const Cross = styled(Icon)`
  width: 15px;
  fill: ${(p) => p.theme.colours.secondary.three};
`;

const CharityName = ({ children }) => (
  <div style={{ fontWeight: 700, fontSize: 16 }}>{children}</div>
);

const Date = ({ children }) => <div>{format(children)}</div>;

const Boolean = ({ children }) =>
  children ? <Check icon='check' /> : <Cross icon='close' />;

const columns = [
  { label: 'Name', key: 'name', cell: CharityName },
  { label: 'Date Added', key: 'createdAt', cell: Date },
  { label: 'Users', key: 'userCount' },
  { label: 'Pets', key: 'petCount' },
  { label: 'Visible', key: 'isEnabled', cell: Boolean },
];

const List = ({ charities, loading }) => {
  const { isDesktop } = useWidth();
  const { push } = useHistory();

  const handleClick = ({ _id }) => {
    push(`/charity/${_id}`);
  };

  if (loading) return null;

  if (isDesktop) {
    return (
      <Table data={charities} columns={columns} onRowClick={handleClick} />
    );
  }

  return charities.map((c, i) => (
    <MobileCard charity={c} key={i} onClick={handleClick} />
  ));
};

export default List;
