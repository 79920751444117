import { useAuth } from 'hooks';
import {
  Input,
  Select,
  CurrencyInput,
  TextArea,
  ImageUpload,
  CharitySelect,
} from 'components/common';

const Form = ({
  details,
  getFormProps,
  options,
  handleChangeImages,
  images,
}) => {
  const { user } = useAuth();

  return (
    <>
      {user.isPawAdmin && <CharitySelect {...getFormProps('charityId')} />}
      <Input label='Name' {...getFormProps('name')} />
      <Select
        label='Species'
        options={options.species}
        {...getFormProps('species')}
      />
      {details.species === 'Dog' && (
        <>
          <Select
            label='Dominant Breed'
            options={options.breeds}
            {...getFormProps('dominantBreed')}
            filterable
          />
          <Select
            label='Cross Breed'
            options={options.breeds}
            {...getFormProps('crossBreed')}
            required={false}
            filterable
          />
        </>
      )}
      <Select
        label='Status'
        options={options.status}
        {...getFormProps('status')}
      />
      <Input label='Location (Town/City)' {...getFormProps('location')} />
      <Input
        type='date'
        label='Arrived Date'
        {...getFormProps('arrivedDate')}
      />
      <Select label='Sex' options={options.sex} {...getFormProps('sex')} />
      <Select
        label='Main Colour(s)'
        options={options.colour}
        multi
        {...getFormProps('colour')}
      />
      {details.species === 'Dog' && (
        <Select
          label='Size (when adult)'
          options={options.size}
          {...getFormProps('size')}
        />
      )}
      <Input
        label='Date of Birth'
        type='date'
        {...getFormProps('dateOfBirth')}
      />
      <CurrencyInput label='Rehoming Fee' {...getFormProps('rehomingFee')} />
      {details.status === 'Available to adopt' && (
        <>
          <Select
            label='Minimum age of children'
            options={options.minimumKidAge}
            {...getFormProps('minimumKidAge')}
          />
          <Select
            label='Can live with dogs'
            options={options.liveWithDogs}
            {...getFormProps('liveWithDogs')}
          />
          <Select
            label='Can live with cats'
            options={options.liveWithCats}
            {...getFormProps('liveWithCats')}
          />
          <TextArea
            label='Social Media Summary'
            maxLength={160}
            {...getFormProps('socialBlurb')}
          />
        </>
      )}
      <TextArea
        resize
        lines={8}
        label='Animal Personality Summary'
        {...getFormProps('summary')}
        required={false}
      />
      <ImageUpload
        value={images}
        onChange={handleChangeImages}
        showBlanks={false}
        placeholder='Click here to select images'
        multiple
      />
    </>
  );
};

export default Form;
