import styled from 'styled-components';

import { Icon } from 'components/common';

export const Container = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 30px 25px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colours.primary.dark2};
  position: relative;
  ${(p) => p.theme.breakpoints.desktop} {
    h5 {
      font-size: 20px;
    }
  }
`;

export const Back = styled(Icon)`
  stroke: ${(p) => p.theme.colours.primary.dark2};
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
`;

export const Action = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: ${(p) => p.theme.colours.primary.dark4};
  display: flex;
  align-items: center;
  margin-left: 5px;
  background: white;
  border-radius: 8px;
  padding: 4px 8px;
  border: 1px solid ${(p) => p.theme.colours.primary.dark4};
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 12px;
    margin-left: 15px;
    padding: 8px 16px;
    color: ${(p) => p.theme.colours.primary.dark4};
    cursor: pointer;
  }
`;

export const BinIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.dark4};
  margin-right: 5px;
  width: 14px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 16px;
  }
`;

export const ActionsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
