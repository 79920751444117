import { useEffect, useRef, useState } from 'react';

import { format } from 'utils/date';
import { application } from 'services/api';
import { usePost, useNotification } from 'hooks';

import {
  Container,
  ApplicationContainer,
  Header,
  FieldsContainer,
  Fields,
  Field,
  Label,
  Value,
  Expand,
  IconContainer,
  ExpandIcon,
  Details,
  Calendar,
  SubmittedDate,
  Actions,
  ActionIconContainer,
  ActionIcon,
  Select,
  Button,
  RightActions,
  Alert,
} from './styled';
import ChooseApplicant from 'components/ChooseApplicant';

import { getChooseApplicantApplications } from '../helpers';

const filterOptions = [
  { label: 'Thumbs Up', value: 'approved' },
  { label: 'Thumbs Down', value: 'rejected' },
  { label: 'Unprocessed', value: 'unprocessed' },
];

const Application = ({
  fields,
  createdAt,
  _id,
  status,
  onUpdateApplicationStatus,
  isSuccessful,
  type,
}) => {
  const fieldsRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [containerHeight, setContainerHeight] = useState(150);
  const [{ res, loading }, postApplication, setRes] = usePost({
    url: application,
  });

  useEffect(() => {
    if (expanded) {
      const { height } = fieldsRef.current.getBoundingClientRect();
      setContainerHeight(height + 50);
    } else {
      setContainerHeight(150);
    }
  }, [expanded]);

  useEffect(() => {
    if (res) {
      onUpdateApplicationStatus(res.status);
      setRes();
    }
  }, [res, onUpdateApplicationStatus, setRes]);

  const toggleExpand = () => setExpanded((s) => !s);

  const handleStatusChange = (newStatus) => {
    if (newStatus !== status) {
      postApplication({ id: _id, status: newStatus });
    }
  };

  return (
    <ApplicationContainer>
      <FieldsContainer onClick={toggleExpand} height={containerHeight}>
        <Fields ref={fieldsRef}>
          {fields.map(({ label, value }, i) => (
            <Field key={i}>
              <Label>{label}</Label>
              <Value>{value === true ? 'Yes' : value}</Value>
            </Field>
          ))}
        </Fields>
        <Expand>
          <IconContainer>
            <ExpandIcon icon='chevron' expanded={expanded} />
          </IconContainer>
        </Expand>
      </FieldsContainer>
      <RightActions>
        {isSuccessful && <Alert type='success'>Successful Application</Alert>}
        {type === 'manual' && <Alert type='info'>Manual</Alert>}
        <Details>
          <SubmittedDate>
            <Calendar icon='calendar' />
            {format(createdAt, 'DD/MM/YYYY', false)} @{' '}
            {format(createdAt, 'HH:mm', false)}
          </SubmittedDate>
          <Actions disabled={loading}>
            <ActionIconContainer
              isActive={status === 'approved'}
              onClick={() => handleStatusChange('approved')}
            >
              <ActionIcon icon='thumb' isActive={status === 'approved'} />
            </ActionIconContainer>
            <ActionIconContainer
              no
              isActive={status === 'rejected'}
              onClick={() => handleStatusChange('rejected')}
            >
              <ActionIcon icon='thumb' no isActive={status === 'rejected'} />
            </ActionIconContainer>
          </Actions>
        </Details>
      </RightActions>
    </ApplicationContainer>
  );
};

const Applications = ({ pet, onFetch }) => {
  const [filter, setFilter] = useState(['approved', 'unprocessed']);
  const [applications, setApplications] = useState(pet?.applications);
  const [showChooseApplicant, setShowChooseApplicant] = useState(false);
  const { notify } = useNotification();

  useEffect(() => {
    if (pet && !applications) {
      setApplications(pet.applications);
    }
  }, [pet, applications]);

  const handleUpdateApplicationStatus = (id) => (status) => {
    const found = applications.find((app) => app._id === id);
    const without = applications.filter((app) => app._id !== id);

    setApplications([...without, { ...found, status }]);
  };

  const chooseApplicantApplications =
    getChooseApplicantApplications(applications);

  let _applications = filter?.length ? [] : applications;
  if (filter && filter.length && applications) {
    const sorted = [...applications];
    sorted.sort((a, b) => new Date(b) - new Date(a));
    if (filter.includes('unprocessed')) {
      const unprocessed = sorted.filter((app) => !app.status);
      _applications.push(...unprocessed);
    }
    if (filter.includes('approved')) {
      const approved = sorted.filter((app) => app.status === 'approved');
      _applications.push(...approved);
    }
    if (filter.includes('rejected')) {
      const rejected = sorted.filter((app) => app.status === 'rejected');
      _applications.push(...rejected);
    }
    if (pet?.successfulApplication) {
      _applications = _applications.filter(
        (app) => app._id !== pet?.successfulApplication
      );
      _applications.unshift(
        applications.find((app) => app._id === pet?.successfulApplication)
      );
    }
  }

  const handleSuccess = () => {
    onFetch();
    setShowChooseApplicant(false);
    notify('Email sent to unsuccessful applicants!');
  };

  return (
    <>
      {chooseApplicantApplications.length > 0 &&
        !pet?.successfulApplication && (
          <Button onClick={() => setShowChooseApplicant(true)}>
            Choose successful applicant
          </Button>
        )}
      <Container>
        <Header>
          <h6>Applications</h6>
          <Select
            label='Filter Applications'
            options={filterOptions}
            value={filter}
            onChange={setFilter}
            multi
          />
        </Header>
        {_applications?.map((a, i) => (
          <Application
            {...a}
            key={i}
            isSuccessful={a._id === pet?.successfulApplication}
            onUpdateApplicationStatus={handleUpdateApplicationStatus(a._id)}
          />
        ))}
      </Container>
      <ChooseApplicant
        show={showChooseApplicant}
        applications={chooseApplicantApplications}
        onClose={() => setShowChooseApplicant(false)}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default Applications;
