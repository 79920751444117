import { useEffect } from 'react';
import { useHistory, useParams, useRouteMatch, Route } from 'react-router';

import { useGet, useDel, usePrevious, useAuth } from 'hooks';
import { pet, petOptions } from 'services/api';
import { Page } from 'components/common';

import Nav from './Nav';
import Details from './Details';
import Images from './Images';
import Applications from './Applications';
import {
  Container,
  Header,
  Back,
  BinIcon,
  Action,
  ActionsContainer,
} from './styled';

const Actions = ({ onDelete, onDuplicate }) => {
  const { user } = useAuth();

  return (
    <ActionsContainer>
      <Action onClick={onDuplicate}>
        <span>Duplicate Pet</span>
      </Action>
      {user.isPawAdmin && (
        <Action onClick={onDelete}>
          <BinIcon icon='bin' />
          <span>Delete</span>
        </Action>
      )}
    </ActionsContainer>
  );
};

const Profile = () => {
  const { push } = useHistory();
  const { id } = useParams();
  const prevId = usePrevious(id);

  const [{ res: deleteRes }, deletePet] = useDel({ url: `${pet}/${id}` });

  const [{ res: petInfo, loading: loadingInfo }, getInfo, setPetInfo] = useGet({
    url: `${pet}/${id}`,
    onMount: !!id,
  });

  const [{ res: selectOptions, loading: loadingOptions }] = useGet({
    url: petOptions,
    onMount: true,
  });

  useEffect(() => {
    if (prevId && !id) {
      setPetInfo();
    }
  }, [id, prevId, setPetInfo]);

  const goToDashboard = () => {
    push('/');
  };

  const getPageTitle = () => {
    if (id && !petInfo) return '-';
    if (petInfo) return petInfo.name;
    return 'New Pet';
  };

  useEffect(() => {
    if (deleteRes) {
      push('/');
    }
  }, [deleteRes, push]);

  const handleDuplicate = () => push('/pet?mode=duplicate');

  const loading = loadingInfo || loadingOptions;
  const { path } = useRouteMatch();

  return (
    <Page>
      <Container>
        <Header>
          <Back onClick={goToDashboard} icon='back' />
          <h5>{getPageTitle()}</h5>
          {id && <Actions onDelete={deletePet} onDuplicate={handleDuplicate} />}
        </Header>
        <Nav />
        <Route exact path={path}>
          <Details
            pet={petInfo}
            options={selectOptions}
            loading={loading}
            onFetch={getInfo}
          />
        </Route>
        <Route path={`${path}/images`}>
          <Images
            pet={petInfo}
            options={selectOptions}
            loading={loading}
            onFetch={getInfo}
          />
        </Route>
        <Route path={`${path}/applications`}>
          <Applications
            pet={petInfo}
            options={selectOptions}
            loading={loading}
            onFetch={getInfo}
          />
        </Route>
      </Container>
    </Page>
  );
};

export default Profile;
